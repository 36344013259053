<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    const prefersLight = window.matchMedia("(prefers-color-scheme: light)").matches;
    const prefersLightBrowser = window.matchMedia("(prefers-color-scheme: light)").matches;

    let favicon = prefersLight || prefersLightBrowser ? "/favicon_io/light-mode.ico" : "/favicon_io/dark-mode.ico";
    favicon = window.location.protocol + "//" + window.location.host + favicon;
    this.changeFavicon(favicon);
  },

  methods: {
    changeFavicon(newFavicon) {
      const favicon = document.querySelector("link[rel='icon']");
      if (favicon) {
        favicon.href = `${newFavicon}`;
      }
    },
  },
};
</script>

<style></style>
